.tableau-programme {
	table {
		border-collapse: collapse;
	}

	td,
	th {
		border: 1px solid #e7e6e6;
	}

	td {
		height: 150px;
		width: 300px;
		// width: 92%;
		vertical-align: top;
	}
}

.saisons {
	transform: rotate(180deg) !important;
	font-size: 1.5em;
	font-weight: normal;
	writing-mode: vertical-lr;
	padding-left: 10px;
	padding-right: 10px;
}

.saisonsMobile {
	transform: rotate(180deg) !important;
	font-size: 1.3em;
	font-weight: normal;
	writing-mode: vertical-lr;
	// padding-left: 10px;
	// padding-right: 10px;
}

.pasDeCamps {
	background-color: #d1d3d4;
}

.camps {
	background-color: #ffffff;
}

.printemps {
	background-color: #dcddde;
	color: #424143;
}

.ete {
	background-color: #79797a;
	color: #ffffff;
}

.automne {
	background-color: #2d5468;
	color: #32ffff;
}

.hiver {
	background-color: #009d96;
	color: #ffffff;
}

$texte-noir: #414042;
$texte-noir2: #414042;
// $color-menu: #464547; // site avec des cris de joie
$color-menu: #009d96; // site Taj
// $color-menu: #b11f27; // site mission
$green: #009d96;
// $green2: #39beb7;
$green2: #ffffff;
$green3: #009c97;
// $green: #39beb7;
$blue: #2d5467;
$blue-light: #9fc4d7;
$blue-fonce: #2f5568;
$grey: #666;
$grey2: #424242;
// $grey: #414042;
$grey-light: #e7e6e6;

// nouveau design

// Les couleurs :

$orange: #f2c717;
$bleu: #009d96;
$gris: #e2e3e4;
$gris2: #c9cacc;
$gris3: #414042;
$gris4: #afb1b4;

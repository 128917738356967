.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
	background-color: #810c1c !important;
	border-color: #8b0d1e !important;
}

.btn-primary,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
	background-color: #b01125 !important;
	border-color: #b01125 !important;
}

.label-cgu {
	a {
		color: #007bff !important;
		//color: #424242 !important;
		//font-weight: bold;
		&:hover {
			//color: #b01125 !important;
		}
	}
}

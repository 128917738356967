@import '../../styles/variables';

@import 'bootstrap';

.backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.3);
	z-index: 100;
}

.toggle-button {
	height: 24px;
	width: 30px;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	box-sizing: border-box;

	&:focus {
		outline: none;
	}

	.toggle-button__line {
		width: 30px;
		height: 2px;
		background: $green2;
	}
}
.toolbar {
	background-color: $color-menu;
	height: 50px;

	.toolbar__items {
		height: 100%;

		ul {
			list-style: none;
			height: 100%;
			padding-left: 0;
			margin-left: 1rem;
		}

		li.menuitem {
			padding: 0 0.5rem;
			text-transform: uppercase;
			font-weight: bold;
			letter-spacing: -1px;
			font-size: larger;
			color: $green2;
			height: 100%;
			line-height: 50px;

			&::after {
				content: '';
				border: none;
				border-right: $green2 1px solid;
				height: 26px;
				margin-top: -12px;
				left: 9px;
				position: relative;
			}
			&:nth-last-of-type(1)::after {
				content: none;
			}

			a,
			a:hover,
			a:active {
				color: $green2;
				text-decoration: none;
			}
		}

		.submenu {
			& ul {
				display: none;
			}
			&:hover ul {
				display: flex;
				background-color: white;
				position: fixed;
				top: 50px;
				margin-left: -9px;
				height: auto;
				text-transform: none;
				font-weight: normal;
				font-size: initial;
				padding: 1rem 1rem;
				li {
					line-height: 30px;
					color: $texte-noir;
					a {
						color: $texte-noir;
						text-decoration: none;
					}
					a:hover {
						color: $green;
						// background-color: $grey-light;
						font-weight: bold;
					}

					&.arrow-down {
						position: fixed;
						top: 50px;
					}
				}
			}
		}
	}
}

.arrow-down {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-top: 10px solid $color-menu;
}

.custom-dropdown {
	position: fixed;
	top: 50px;
	left: 0;
	background-color: $color-menu;
	width: 100%;
	list-style: none;
	padding-bottom: 0.5rem;
	padding-left: 0;
	margin-bottom: 0;
	z-index: 150;

	transform: translateY(-100%);
	transition: transform 0.3s ease-out;

	&.open {
		transform: translateY(0%);
	}

	li {
		padding: 0.5rem 0;
		text-transform: uppercase;
		font-weight: bold;
		font-size: larger;
		color: $green2;

		a,
		a:hover,
		a:active {
			color: $green2;
			text-decoration: none;
		}
	}

	.submenu {
		ul {
			list-style: none;
			display: none;
			background-color: white;
			text-transform: none;
			font-weight: normal;
			font-size: initial;
			padding: 1rem 2rem;

			&.open {
				display: flex;
			}

			li.arrow-down {
				position: relative;
				top: -17px;
				margin-bottom: -30px;
			}

			li {
				text-transform: unset;
				a {
					color: $texte-noir;
					&:active {
						color: $green2;
					}
				}
			}
		}
	}
}

.text-menu {
	letter-spacing: 0px !important;
	font-family: 'Lato';
	font-weight: 300 !important;
	font-size: 1em !important;
}

@import '../../styles/variables';

@import 'bootstrap';

.blocHome {
	.contenu-h1,
	.contenu-h2 {
		font-size: 1.8em;
	}
}

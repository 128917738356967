@import 'variables';
// @import "bootstrap/scss/functions";
// @import "bootstrap/scss/variables";
// @import "bootstrap/scss/mixins";

@import 'bootstrap';

body {
	// font-family: Trebuchet MS, Lucida Grande, Lucida Sans Unicode, Lucida Sans,
	// 	Tahoma, sans-serif !important;
	font-family: 'Alegreya Sans', sans-serif, Trebuchet MS, Lucida Grande,
		Lucida Sans Unicode, Lucida Sans, Tahoma, sans-serif !important;
	font-weight: 300 !important;
	color: $gris3 !important;
}

p {
	text-align: justify;
	font-size: 1.2em;
}

.texte {
	font-size: 1.2em;
}

.lien {
	color: $gris3 !important;
	font-weight: 400;
	// color: $green !important;
	text-decoration: none;
	background-color: transparent;
}

.liste li {
	padding-left: 45px;
	padding-top: 0px;
	background: url('../resources/icons/fleche jaune-essai.svg') no-repeat 7px -2px
		transparent;
	list-style-type: none;
	margin: 0;
	vertical-align: middle;
	font-size: 1.2em;
}

.liste {
	padding-left: 0px;
}

.no-gutters {
	padding-left: 0px !important;
	padding-right: 0px !important;
}

.module-medium {
	padding: 75px 0px;
}

.module-small {
	padding: 40px 0;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
	color: $green;
}

.green {
	color: $green;
}

.App h1 {
	color: #ffffff;
}

.scroll-up a:hover,
.scroll-up a:active {
	opacity: 1;
	color: #000;
}

.scroll-up a {
	background: #fff;
	display: block;
	height: 28px;
	width: 28px;
	text-align: center;
	line-height: 28px;
	font-size: 14px;
	color: #000;
	opacity: 0.6;
	border-radius: 2px;
}

.scroll-up {
	position: fixed;
	display: none;
	bottom: 7px;
	right: 7px;
	z-index: 999;
}

// iframe {
// 	border: 0px !important;
// }

// @media (min-width: 1600px) {
// 	.App .col-xxl-2,
// 	.App .col-xxl-10 {
// 		position: relative;
// 		width: 100%;
// 		min-height: 1px;
// 		padding-right: 15px;
// 		padding-left: 15px;
// 	}

// 	.App .col-xxl-2 {
// 		flex: 0 0 16.66%;
// 		max-width: 16.66%;
// 	}

// 	.App .col-xxl-10 {
// 		flex: 0 0 83.33%;
// 		max-width: 83.33%;
// 	}
// }

// **********************************************************************************
//  TABLEAUX DE PROGRAMMES

.tdResponsive {
	height: 150px;
	// width: 300px;
	width: 92%;
	vertical-align: top;
}

.colos {
	height: 150px;
	width: 800px;
	vertical-align: top;
}

.lieu {
	height: 60px;
	background-color: #414042;
	font-size: 1.75em;
	font-weight: normal;
}

.ligne {
	width: 63px !important;
	// margin-left: 10px;
	// margin-right: 10px;
}

.ligneResponsive {
	width: 55px !important;
}

.typeCamp {
	background-color: #bde6e4;
	color: #11a49d;
	vertical-align: top;
}

// **************************************************************************************

// POUR COLO REACT
.InscriptionMain .table-hover.text-white tbody tr:hover {
	color: white !important;
}
.InscriptionMain .bg-blue-fonce .table,
.InscriptionMain .bg-blue-fonce .table-hover tbody tr:hover {
	color: white;
}
.InscriptionMain .bg-blue-fonce .table thead {
	background-color: #009d96;
}

.InscriptionMain .bg-blue-fonce {
	color: white;
}

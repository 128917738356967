@import '../../styles/variables';
@import 'bootstrap';

.slider-background {
	height: 500px;
	// height: 650px;
}

// .slider-background .row {
// 	height: 200px;
// 	position: relative;
// 	top: 210px;
// }

// .slider-background .title h1,
// .slider-background .title h2 {
// 	position: relative;
// }

// .slider-background .title h1 {
// 	top: 30%;
// 	text-transform: uppercase;
// }

// .slider-background .title h2 {
// 	top: 30%;
// 	color: white;
// }

// .slider-background .title .logo {
// 	position: relative;
// 	top: 3.5rem;
// }

// @include media-breakpoint-up(xl) {
// 	.slider-background .title .logo {
// 		top: 36px;
// 	}
// }

// .slider-background .encart-verset {
// 	color: white;
// }

// .slider-background .encart-verset .fa {
// 	color: lighten($green, 10%);
// }

// .slider-background .encart-verset .verset,
// .slider-background .encart-verset .verset-reference {
// 	font-weight: bold;
// 	font-size: large;
// }

// @include media-breakpoint-between(sm, md) {
// 	.slider-background .encart-verset .verset,
// 	.slider-background .encart-verset .verset-reference {
// 		font-size: medium;
// 	}
// }

.connect-language img {
	height: 25px;
	margin-top: 4px;
}

.connect-language span {
	text-transform: uppercase;
	font-size: 20px;
	line-height: 38px;
}

.connect-language span.auth,
.connect-language span.auth a {
	// color: $color-menu;
	color: $grey !important;
}

.MuiInputBase-root-14 {
	color: $grey !important;
}

.connect-language a:hover span {
	text-decoration: underline;
}

.connect-language div * {
	float: left;
}

.connect-language span.connected {
	color: $green;
}

div#ReactBackgroundSlider figure {
	background-attachment: fixed;
	height: 650px;
	background-position: 50% 0px;
	background-size: cover;
}

div#ReactBackgroundSlider figure.figureReactBackgroundSlider {
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	color: transparent;
	background-repeat: no-repeat;
	z-index: -1000;
	margin: 0;
	opacity: 1;
	animation: none;
}

@media (max-width: 1599px) {
	div#ReactBackgroundSlider figure[style] {
		// height: 400px;
	}
	.slider-background {
		// height: 400px;
	}
	// .slider-background .title h1 {
	// 	top: 15%;
	// }
	// .slider-background .title h2 {
	// 	top: 15%;
	// }
}

@media (max-width: 960px) {
	.titreSlide > h1 {
		font-size: 300% !important;
	}
}
